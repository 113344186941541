import React from "react"
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import SwirlContainer from '../container-fluid-swirl'
import Layout from '../layout'

const build_head_from = (pageContext, siteMetadata) => {
  const { baseUri, title } = siteMetadata

  const head = {
    title: `Categories - ${title}`,

    link: [
      { rel: "canonical", href: baseUri + pageContext.url },
    ],
  }

  return head
}

export default ({
  data,
  pageContext,
}) => {
  const { site } = data
  const { categories } = pageContext

  return <Layout>
    <Helmet {...build_head_from(pageContext, site.siteMetadata)}
    />

    <SwirlContainer>
      &nbsp;
    </SwirlContainer>

    <section className="container">
      <article className="post-content">
        <header className="pb-2 my-4 border-bottom">
          <h1>Categories</h1>
        </header>

        <ul className="list-unstyled row">
          { categories.map(category =>
            <li key={category.slug} className="col-12 col-md-6 col-lg-4">
              <Link to={category.url}>
                {category.label}
              </Link>
              <small className="text-muted float-right">{category.count.toLocaleString()}</small>
            </li>
          ) }
        </ul>
      </article>
    </section>
  </Layout>
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        baseUri
      }
    }
  }
`
